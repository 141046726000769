
<template>
    <div class="user-content">
        <div class="user-info-title">我的收藏
          <el-input placeholder="搜索关键词快速找地" v-model="data.keyword" style="width: 200px; float: right; margin-top: -3px" class="search" v-if="false">
            <template v-slot:append>
              <el-button type="primary" icon="el-icon-search"  />
            </template>
          </el-input></div>
        <div class="user-info-list">
            <el-table :data="data.tableData" style="width: 100%">
                <el-table-column type="index" label="序号" width="67">
                </el-table-column>
                <el-table-column prop="title" label="资源名称"  width="230">
                  <template  #default="scope">
                    <RouterLink :to="'/land/detail/' + scope.row.refId">{{
                        scope.row.title
                      }}</RouterLink>
                  </template>
                </el-table-column>
              <el-table-column prop="landPurpose" label="土地用途" width="120">
                <template #default="scope">
                  {{ findLabel(demand_purpose, scope.row.landInfo.landPurpose) }}
                </template>
              </el-table-column>
              <el-table-column  label="交易方式" width="120">
                <template #default="scope">
                  {{ findLabel(transaction_way,scope.row.landInfo.transactionWay) }}
                </template>
              </el-table-column>
              <el-table-column  label="土地面积" width="120">
                <template #default="scope">
                  {{ scope.row.landInfo.landSize}}
                </template>
              </el-table-column>
                <el-table-column prop="createTime" label="收藏时间"  width="180">
                </el-table-column>
                <el-table-column prop="action" label="操作"  width="120">
                    <template #default="scope">
                        <el-button icon="el-icon-delete" type="text" @click="delFavorite(scope.row)" >删除</el-button>
                    </template>
                </el-table-column>

            </el-table>
          <div class="user-info-list-page">
            <el-pagination background style="float: right" layout="total,  prev, pager, next" :page-count="data_page.totalPage" :current-page="data_page.currentPage" @current-change="pageChange"  >
            </el-pagination>
          </div>
        </div>
    </div>
</template>
<script setup>
import { reactive,onMounted } from 'vue'
import CommonService from '../../service/CommonService';
import { MessageBox, Message } from 'element-ui';
import { RouterLink } from "vue-router";
import { useDict, findLabel } from "@/hooks/dict";
const { demand_purpose,transaction_way } = useDict('demand_purpose', 'transaction_way')

const data_page = reactive({
  pageSize:10,
  currentPage:1,
  totalPage:1,
  tableData: [

  ]
})

const pageChange = (page) =>{
  loadFavorite(page);
}

const data = reactive({
    tableData: [
        
    ]
})

const loadFavorite = () => {
    CommonService.getFavorite().then(res => {
        if (res && res.data) {


          console.log(res.data.records);
            data.tableData = res.data.records;
        }
    })
}

const delFavorite = async (item) => {
    try {
		await MessageBox.confirm('此操作将永久删除','提示', {
          type: 'warning'
        });
	} catch(err) {
        console.log(err)
		return;
	}

	try {
    // console.log(item)
		await CommonService.delFavorite(item.landInfo.id,item.type);
		loadFavorite();
		Message.success('删除成功');
	} catch (err) {
		Message.error(err.msg);
	}    
}

onMounted(() => {
    loadFavorite()
})

</script>

